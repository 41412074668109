<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['express_CourierDetail'])"></div>
        {{$t(`express_CourierDetail.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="transaction-info__row">
          <div class="transaction-info__col">
            <div class="transaction-info__label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_confirmationNumber'])"></div>
              {{$t(`express_confirmationNumber.localization_value.value`)}}
            </div>
            <div class="transaction-info__txt">
              {{item.confirmationNumber}}
            </div>
          </div>
          <div class="transaction-info__col">
            <div class="transaction-info__label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_courierArrivalDate'])"></div>
              {{$t(`express_courierArrivalDate.localization_value.value`)}}
            </div>
            <div class="transaction-info__txt">
              {{ item.courierArrivalDate | moment("DD MMM, YYYY") }}
            </div>
          </div>
        </div>
        <div class="transaction-info__row">
          <div class="transaction-info__col">
            <div class="transaction-info__label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_contactPersonName'])"></div>
              {{$t(`express_contactPersonName.localization_value.value`)}}
            </div>
            <div class="transaction-info__txt">
              {{item.contactPersonName}}
            </div>
          </div>
          <div class="transaction-info__col">
            <div class="transaction-info__label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_contactPhone'])"></div>
              {{$t(`express_contactPhone.localization_value.value`)}}
            </div>
            <div class="transaction-info__txt">
              {{item.contactPhone}}
            </div>
          </div>
        </div>
        <div class="transaction-info__row">
          <div class="transaction-info__col">
            <div class="transaction-info__label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_packageLocation'])"></div>
              {{$t(`express_packageLocation.localization_value.value`)}}
            </div>
            <div class="transaction-info__txt">
              {{item.packageLocation}}
            </div>
          </div>
          <div class="transaction-info__col">
            <div class="transaction-info__label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_timeFromTo'])"></div>
              {{$t(`express_timeFromTo.localization_value.value`)}}
            </div>
            <div class="transaction-info__txt">
              {{ item.timeFrom | moment("HH:mm") }} - {{ item.timeTo | moment("HH:mm") }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
              'common_close',
            ])"></div>
        <span
            class="site-link site-link--alt"
            @click="$emit('close')"
        >
          {{ $t('common_close.localization_value.value') }}
        </span>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'

export default {
  name: "CourierDetailPopup",
  components: {
    Modal,
  },

  props: {
    item: Object,
  },

  data() {
    return {

    }
  },
}

</script>

<style lang="scss" scoped>

</style>
