<template>
  <modal
      @close="$emit('close')"
      class="choose-express-order-modal choose-express-order"
  >
    <template slot="header">
      <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['consolidations_whatOrderNeed'])"></span>
        {{$t('consolidations_whatOrderNeed.localization_value.value')}}
      </span>
    </template>
    <template slot="body">

      <div class="what-order-create__list custom-row">
        <div v-if="!euProductsTab && !isLatvian && navTabs && navTabs[ORDERS_CONSOLIDATION_TYPE.FEDEX]" class="what-order-create__item custom-col custom-col--50 custom-col--md-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_USAFedex',])"></div>
          <router-link
              :to="$store.getters.GET_PATHS.ordersConsolidationCreate + '/' + ORDERS_CONSOLIDATION_TYPE.FEDEX"
              class="what-order-create__link flex-column"
          >
            <div class="what-order-create__name">
              {{ $t('consolidations_USAFedex.localization_value.value') }}
            </div>
          </router-link>
        </div>


        <template v-if="!(isUkrainian || isUserAlternativeContactUkraine)">
          <div v-if="!euProductsTab && navTabs && navTabs[ORDERS_CONSOLIDATION_TYPE.DHL_USA] && (isUkrainian || isAdmin)" class="what-order-create__item custom-col custom-col--50 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['consolidations_USADHL',])"></div>
            <router-link
                :to="$store.getters.GET_PATHS.ordersConsolidationCreate + '/' + ORDERS_CONSOLIDATION_TYPE.DHL_USA"
                class="what-order-create__link flex-column"
            >
              <div class="what-order-create__name">
                {{ $t('consolidations_USADHL.localization_value.value') }}
              </div>
            </router-link>
          </div>
        </template>


        <div v-if="!euProductsTab && navTabs && navTabs[ORDERS_CONSOLIDATION_TYPE.DPD]" class="what-order-create__item custom-col custom-col--50 custom-col--md-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_Europe',])"></div>
          <router-link
              :to="$store.getters.GET_PATHS.ordersConsolidationCreate + '/' + ORDERS_CONSOLIDATION_TYPE.DPD"
              class="what-order-create__link flex-column"
          >
            <div class="what-order-create__name">
              {{ $t('consolidations_Europe.localization_value.value') }}
            </div>
          </router-link>
        </div>

        <div class="what-order-create__item custom-col custom-col--50 custom-col--md-100"
             v-if="navTabs && navTabs[ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT]"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_EuropeProductLodz',])"></div>
          <router-link
              :to="$store.getters.GET_PATHS.ordersConsolidationCreate + '/' + ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT + '?initStorage=' + LODZ_WAREHOUSE.storage_id"
              class="what-order-create__link flex-column"
          >
            <div class="what-order-create__name">
              {{ $t('consolidations_EuropeProductLodz.localization_value.value') }}
            </div>
          </router-link>
        </div>

<!--        <div class="what-order-create__item custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100"-->
<!--             v-if="navTabs && navTabs[ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT]"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate(['consolidations_EuropeProductCambridge',])"></div>-->
<!--          <router-link-->
<!--              :to="$store.getters.GET_PATHS.ordersConsolidationCreate + '/' + ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT + '?initStorage=' + CAMBRIDGE_WAREHOUSE.storage_id"-->
<!--              class="what-order-create__link flex-column"-->
<!--          >-->
<!--            <div class="what-order-create__name">-->
<!--              {{ $t('consolidations_EuropeProductCambridge.localization_value.value') }}-->
<!--            </div>-->
<!--          </router-link>-->
<!--        </div>-->

        <div class="what-order-create__item custom-col custom-col--50 custom-col--md-100"
             v-if="!euProductsTab && navTabs && navTabs[ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY]"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_yourCity',])"></div>
          <router-link
              :to="$store.getters.GET_PATHS.ordersConsolidationCreate + '/' + ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY"
              class="what-order-create__link flex-column"
          >
            <div class="what-order-create__name">
              {{ $t('consolidations_yourCity.localization_value.value') }}
            </div>
          </router-link>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  // import OrderTypeCardBig from "../../../../coreComponents/OrderTypeCardBig/OrderTypeCardBig";
  import {ORDERS_CONSOLIDATION_TYPE} from "../../../../../staticData/staticVariables";
  import {LODZ_WAREHOUSE, CAMBRIDGE_WAREHOUSE} from "../../../../../staticData/staticVariables";

  export default {
    name: "ChooseConsolidationOrderPopup",
    components: {
      Modal,
      // OrderTypeCardBig,
    },
    props: {
      euProductsTab: {
        type: Boolean,
        default: false,
      },
      navTabs: Object,
    },

    data(){
      return {
        ORDERS_CONSOLIDATION_TYPE: ORDERS_CONSOLIDATION_TYPE,
        LODZ_WAREHOUSE: LODZ_WAREHOUSE,
        CAMBRIDGE_WAREHOUSE: CAMBRIDGE_WAREHOUSE,
      }
    }
  }

</script>

<style scoped>
.order-type-card__ico {
  max-width: 160px;
}
.choose-express-order__item {
  padding: 0 5px;
}

.what-order-create__name {
  font-size: 36px;
  line-height: 42px;
}

@media (max-width: 1120px) {
  .what-order-create__name {
    font-size: 24px;
    line-height: normal;
  }
}

@media (max-width: 550px) {
  .what-order-create__name {
    font-size: 18px;
    line-height: normal;
  }
}

</style>
