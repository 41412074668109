<template>
  <modal
      @close="$emit('close')"
      class="calling-courier-modal calling-courier"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['express_cancelCourier'])"></div>
        {{$t(`common_CancelCourier.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="calling-courier__content">
        <div class="calling-courier__row custom-row">
          <div class="calling-courier__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
              'common_comment',
            ])"></div>
            <TextareaDefault
                :label="$t('common_comment.localization_value.value')"
                v-model="txt"
                :error="validation.txt"
                :errorTxt="$t(`${validationTranslate.txt}.localization_value.value`)"
                :type="'text'"
                v-bind:class="{'ui-no-valid': validation.txt}"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="calling-courier__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
              'common_cancel',
              'common_submit',
            ])"></div>
        <span
            class="site-link site-link--alt calling-courier__btn-i"
            @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            :value="$t('common_submit.localization_value.value')"
            class="calling-courier__btn-i"
            v-on:click.native="submitForm"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import TextareaDefault from '../../../../UI/textarea/TextareaDefault/TextareaDefault.vue'
import {validation} from "../../../../../services/validation";

export default {
  name: "CancelCourierPopup",
  components: {
    Modal,
    MainButton,
    TextareaDefault,
  },

  props: {
    id: [Number, String],
  },

  data() {
    return {
      txt: '',

      validation: {
        txt: false,
      },

      validationTranslate: {
        txt: '',
      },

      validationTxt: {
        txt: false,
      }
    }
  },



  mounted() {

  },

  methods: {

    submitForm() {
      let validationItems = {
        txt: this.txt,
      }

      let validationOptions = {
        txt: {type: ['empty',]}
      }

      let validate = validation(validationItems, validationOptions)
      this.validation = validate.validation
      this.validationTranslate = validate.validationError

      if(validate.isValidate){
        let data = {
          'consolidation_id': this.id,
          'reason': this.txt,
        }


        this.$store.dispatch('consolidationCancelCourier', {data: data}).then((response) => {

          if(!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('reload')
              this.$emit('close')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        });

      }

    },


  }
}

</script>

<style lang="scss">
@import "../../../../../scss/colors";

.calling-courier{

  .modal-component__inner {
    max-width: 740px;
  }


  &__content{

  }

  &__txt{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $borderBrown;
    margin-bottom: 30px;
  }


  &__alert{
    font-size: 12px;
    line-height: 14px;
    color: $black;
  }

  &__btn{
    display: flex;
    align-items: center;
  }

  &__btn-i{
    margin-right: 30px;

    &:last-child{
      margin-right: 0;
    }
  }
}
</style>
