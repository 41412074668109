<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['consolidation_PayOrder'])"></div>
        {{$t('consolidation_PayOrder.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
       <div class="custom-row">
         <div class="custom-col mb-2"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
           <div class="admin-edit" @click="editTranslate(['consolidations_ordersFBMInc', 'consolidation_OrdersAmount'])"></div>
           <b class="mb-2 d-block">{{ $t('consolidations_ordersFBMInc.localization_value.value') }}</b>
           <template
               v-if="payOrderPopupData && _.has(payOrderPopupData, 'totals') && payOrderPopupData.totals.orders.length > 0">
             <div class="statistic-popup__price mb-2 ml-2"
                  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
                  v-for="(order, index) in payOrderPopupData.totals.orders"
                  :key="index"
             >
               <div class="admin-edit" @click="editTranslate(['consolidation_OrdersAmount',])"></div>
               {{ $t('consolidation_OrdersAmount.localization_value.value') }} : #{{order.id}} : <b>${{ order.delivery_cost }}</b>
             </div>
           </template>
         </div>
         <div class="custom-col mb-3"
              v-if="payOrderPopupData && _.has(payOrderPopupData, 'totals')"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
           <div class="admin-edit" @click="editTranslate(['consolidation_consolidationShipment'])"></div>
           <b>{{ $t('consolidation_consolidationShipment.localization_value.value') }} : #{{payOrderPopupData.totals.consolidation.id}} : ${{ payOrderPopupData.totals.consolidation.order_amount }}</b>
         </div>
         <div class="custom-col mb-3"
              v-if="payOrderPopupData && _.has(payOrderPopupData, 'totals')"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
           <div class="admin-edit" @click="editTranslate(['consolidation_consolidationShipment'])"></div>
           <b>{{ $t('consolidations_vatCost.localization_value.value') }} : #{{payOrderPopupData.totals.consolidation.id}} : ${{ payOrderPopupData.totals.consolidation.vat_amount }}</b>
         </div>
         <div class="custom-col"
              v-if="payOrderPopupData && _.has(payOrderPopupData, 'user_balance')"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
           <div class="admin-edit" @click="editTranslate(['consolidation_UserBalance'])"></div>
           <b>{{ $t('consolidation_UserBalance.localization_value.value') }}: ${{ payOrderPopupData.user_balance }}</b>
         </div>

         <br><br>
         <div class="custom-col"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
           <div class="admin-edit" @click="editTranslate(['consolidation_AmountToPay'])"></div>
           <b>{{$t('consolidation_AmountToPay.localization_value.value')}} : ${{payOrderPopupData.total_amount}}</b>
         </div>
       </div>

      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'consolidations_pay'])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('consolidations_pay.localization_value.value')"
            class="buy-label__btn-i"
            @click.native="$emit('pay', payOrderPopupData.totals.consolidation.id)"
        />
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  // import PriceCardBig from "../../../../coreComponents/PriceCardBig/PriceCardBig";

  export default {
    name: "PayOrderPopup",
    components: {
      Modal,
      MainButton,
      // PriceCardBig,
    },

    props: {
      payOrderPopupData: Object,
    }

  }

</script>

<style scoped>

</style>
