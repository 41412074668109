<template>
  <div class="fragment">
    <template v-if="$store.getters.getConsolidation.length > 0 && !$store.getters.getConsolidationLoading">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidations_Id',
          'consolidations_created',
          'common_user',
          'consolidations_shippingMethod',
          'consolidations_shipped',
          'consolidations_received',
          'consolidations_trackingNumber',
          'consolidations_comment',
          'consolidations_Quantity',
          'common_manage',
          'common_termalPrint',
          'common_Proforma',
          'common_Marking',
          'consolidations_pay',
          'consolidations_novaPoshta',
          'consolidations_novaPoshtaEdit',
          'consolidations_novaPoshtaShow',
          'poshta_CreateNovaPoshtaLabel',
          'poshta_Label',
          'poshta_Marking',
          'common_CreateLabel',
          'common_ConfirmLabel',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{ $t('consolidations_Id.localization_value.value') }}</th>
            <th>{{ $t('consolidations_created.localization_value.value') }}</th>
            <th v-if="isAdmin"> {{ $t('common_user.localization_value.value')}}</th>
            <th>{{ $t('consolidations_shippingMethod.localization_value.value') }}</th>
            <th>{{ $t('consolidations_shipped.localization_value.value') }}</th>
            <th>{{ $t('consolidations_received.localization_value.value') }}</th>
            <th>{{ $t('consolidations_comment.localization_value.value') }}</th>
            <th>{{ $t('consolidations_trackingNumber.localization_value.value') }}</th>
            <th class="text-lg-right white-space-line pr-3">
              {{ $t('consolidations_Quantity.localization_value.value') }}
            </th>
            <th>{{ $t('common_manage.localization_value.value') }}</th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-bind:class="{
                'site-table__draft-row': item.in_draft === 1
              }"
              v-for="(item, index) in $store.getters.getConsolidation"
              :key="index"
          >
            <td>
              <DefaultCheckbox
                  class="empty-label"
                  :selectedNow="selectedNow"
                  :dataValue="item.id"
              />
            </td>
            <td>
              <div class="d-flex align-items-center">
                <router-link
                    :to="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +
												getConsolidationTypeByDestinationId(item.consolidation_destination_id) + '/' + item.id"
                    class="table-link btn-style">
                  #{{ item.id }}
                </router-link>
              </div>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td v-if="isAdmin">
              <TableUserColumn
                  v-if="isAdmin"
                  :item="item"
                  :withCountry="true"
              />
            </td>
            <td>
              <div class="d-flex align-items-center"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'consolidations_USA',
                  'consolidations_Europe',
                  'consolidations_EuropeProducts',
                  'consolidations_fedex',
                  'consolidations_dhl',
                  'consolidations_yourCity',
                  'consolidations_ownCarrier',
                  ])"></div>
                <template v-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id === item.consolidation_destination.id">
                  {{ $t('consolidations_dhl.localization_value.value') }} ({{$t('consolidations_yourCity.localization_value.value')}})
                </template>
                <template v-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id === item.consolidation_destination.id">
                  {{ $t('consolidations_fedex.localization_value.value') }} ({{$t('consolidations_yourCity.localization_value.value')}})
                </template>
                <template v-else-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id === item.consolidation_destination.id">
                  {{ $t('consolidations_ownCarrier.localization_value.value') }}
                </template>
                <template v-else-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.delivery_service_id === item.consolidation_destination.delivery_service_id">
                  {{ $t('consolidations_USA.localization_value.value') }}
                </template>
                <template v-else-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.id === item.consolidation_destination.id">
                  {{ $t('consolidations_DHLUSA.localization_value.value') }}
                </template>
                <template v-else-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DPD.delivery_service_id === item.consolidation_destination.delivery_service_id">
                  {{ $t('consolidations_Europe.localization_value.value') }}
                </template>
                <template v-else-if="CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_EU_PRODUCT.delivery_service_id === item.consolidation_destination.delivery_service_id">
                  {{ $t('consolidations_EuropeProducts.localization_value.value') }}
                  <template v-if="item.warehouse_storage && item.warehouse_storage.name">
                    ({{item.warehouse_storage.name}})
                  </template>
                </template>
                <template v-else>
                  —
                </template>
                <template v-if="item.consolidation_destination.api === 0 && item.warehouseData">
                  ({{item.warehouseData.currentTranslate.name}})
                </template>
              </div>
            </td>
            <td>
              {{ item.shipped_at | moment("DD MMM, YYYY") }}
            </td>
            <td>
              <template v-if="item.received_at">
                {{ item.received_at | moment("DD MMM, YYYY") }}
              </template>
              <template v-else>
                —
              </template>
            </td>
            <td>
              <template v-if="item.comment">
                <TooltipTextHelper
                    :fullTextWidth="180"
                    :text="item.comment"
                    :paragraph="item.comment"
                />
<!--                <v-popover-->
<!--                    class="site-tooltip"-->
<!--                    offset="5"-->
<!--                    placement="top"-->
<!--                    trigger="hover"-->
<!--                >-->
<!--                  <div class="ellipsis">{{ item.comment }}</div>-->
<!--                  <template slot="popover">-->
<!--                    <p>{{ item.comment }}</p>-->
<!--                  </template>-->
<!--                </v-popover>-->
              </template>
              <template v-else>
                —
              </template>
            </td>
            <td>
              <div class="table-row">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit"
                        @click="editTranslate(['importO_trackingNumber', 'importO_addNumber'])"></span>
                  <template v-if="item.user_tracking_number !== null && item.user_tracking_number !== ''">
                    <!--<span>{{item.tracking_number}}</span>-->
                    <div class="d-flex align-items-center">
                      <TooltipTextHelper>
                        <template slot="text">
                          <div class="table-link btn-style ellipsis">
                            {{item.user_tracking_number}}
                          </div>
                        </template>
                        <template slot="title">
                          {{item.user_tracking_number}}
                        </template>
                      </TooltipTextHelper>
<!--                      <v-popover-->
<!--                          class="site-tooltip"-->
<!--                          offset="5"-->
<!--                          placement="top"-->
<!--                          trigger="hover"-->
<!--                      >-->
<!--                        <div class="table-link btn-style ellipsis">-->
<!--                          {{item.user_tracking_number}}-->
<!--                        </div>-->
<!--                        <template slot="popover">-->
<!--                          <p>{{ item.user_tracking_number }}</p>-->
<!--                        </template>-->
<!--                      </v-popover>-->

                      <div class="table-ico">
                        <!--v-if="item.tracking_sent === 0"-->
                        <v-popover
                            class="site-tooltip"
                            offset="5"
                            placement="top-left"
                            @show="hideEditField = true"
                            @hide="hideEditField = false"
                        >
                        <span class="site-link">
                            <LinkButton
                                :type="'edit'"
                            />
                        </span>
                          <template slot="popover">
                            <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                            </div>
                            <InputEditField
                                :value="item.user_tracking_number"
                                :propId="item.id"
                                :hideEditField="hideEditField"
                                :disabledBtn="$store.getters.getConsolidationBtn"
                                @onChange="changeTrackingNumberConsolidation"
                            />
                            <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                          </template>
                        </v-popover>
                      </div>
                    </div>

                  </template>
                  <template v-else>
                    <div class="">
                      <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                      >
                        <span class="table-link btn-style">{{ $t('importO_addNumber.localization_value.value') }}</span>

                        <template slot="popover">
                          <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                          </div>
                          <InputEditField
                              :value="''"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              :disabledBtn="$store.getters.getConsolidationBtn"
                              @onChange="changeTrackingNumberConsolidation"
                          />

                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                </div>
              </div>
            </td>
            <td align="right" class="pr-3">
              <ValueHelper
                  :value="item.product_entities_count"
              />
            </td>
            <td>
              <div class="table-right table-manage-list">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton">

                  <template v-if="isFromYourCityType(item) &&
                  item.file_entities && item.file_entities.length > 0 && getFileUUID(item.file_entities, 'pdf', 'userDocumentPackageFileType')
                  && (!item.warehouse || isAdmin)" slot="item">
                    <LinkButton
                        :value="$t(`${item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id ? 'consolidation_fedexDocs' : 'common_DocsDHL'}.localization_value.value`)"
                        :link="$store.getters.GET_PATHS.getPdfFile + '/' + getFileUUID(item.file_entities, 'pdf', 'userDocumentPackageFileType')"
                        :type="'docs'"
                        :target="'_blank'"
                    />
                  </template>

                  <template slot="item">
                    <LinkButton
                        v-if="isAdmin || (!isAdmin && item.prepay === 0 && (item.in_draft || item.status === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_IN_PROGRESS.value))"
                        :value="item.in_draft ? $t('common_EditDraft.localization_value.value') : $t('common_edit.localization_value.value')"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.ordersConsolidationEditing + '/' + getConsolidationTypeByDestinationId(item.consolidation_destination_id) + '/' + item.id"
                    />
                  </template>

                  <template slot="item">
                    <LinkButton
                        v-if="isAdmin || (!isAdmin && item.prepay === 0 && (item.in_draft || item.status === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_IN_PROGRESS.value))"
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="$emit('removeItem', item.id)"
                    />
                  </template>
                  <template v-if="!(item.in_draft || item.consolidation_destination.api) && (item.prepay === 1 || isAdmin || !isUserPrepayment)" slot="item">
                    <LinkButton
                        :value="$t('common_Proforma.localization_value.value')"
                        :type="'proforma'"
                        @click.native="$emit('getConsolidationProform', item.id)"
                    />
                  </template>
                  <template v-if="isFromYourCityType(item) &&
                  item.file_entities && item.file_entities.length > 0 && getFileUUID(item.file_entities, 'docx', 'proformFileType')" slot="item">
                    <LinkButton
                        :value="$t('common_Proforma.localization_value.value')"
                        :type="'proforma'"
                        @click.native="$emit('getConsolidationApiProform', getFileUUID(item.file_entities, 'docx', 'proformFileType'))"
                    />
                  </template>
                  <template v-if="!item.in_draft && (item.prepay === 1 || isAdmin || !isUserPrepayment)" slot="item">
                    <LinkButton
                        :value="$t('common_Marking.localization_value.value')"
                        :type="'mark'"
                        @click.native="$emit('getConsolidationMarking', {id: item.id})"
                    />
                  </template>
<!--                  <template v-if="!item.in_draft" slot="item">-->
<!--                    <LinkButton-->
<!--                        :value="'Docs DHL'"-->
<!--                        :type="'docs'"-->
<!--                    />-->
<!--                  </template>-->
<!--                  <template v-if="!item.in_draft" slot="item">-->
<!--                    <LinkButton-->
<!--                        :value="'№ Reference'"-->
<!--                        :type="'reference'"-->
<!--                    />-->
<!--                  </template>-->
<!--                  <template v-if="!item.in_draft" slot="item">-->
<!--                    <LinkButton-->
<!--                        :value="'BookingGref'"-->
<!--                        :type="'mark'"-->
<!--                    />-->
<!--                  </template>-->
                  <template v-if="!item.in_draft && (item.prepay === 1 || isAdmin || !isUserPrepayment)" slot="item">
                    <LinkButton
                        v-if="!item.in_draft"
                        :value="$t('common_termalPrint.localization_value.value')"
                        :type="'print'"
                        @click.native="$emit('thermalPrint', {id: item.id})"
                    />
                  </template>

                  <template v-if="isFromYourCityType(item) &&
                    !isAdmin && (!item.file_entities || !getFileUUID(item.file_entities, 'pdf', 'labelFileType'))" slot="item">
                    <LinkButton
                        :value="$t('common_CreateLabel.localization_value.value')"
                        :type="'dog'"
                        @click.native="showBuyLabelPopup(item.id)"
                    />
                  </template>
<!--                  <template v-if="item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id-->
<!--                  && isAdmin && (!item.file_entities || !getFileUUID(item.file_entities, 'pdf', 'labelFileType'))" slot="item">-->
<!--                    <LinkButton-->
<!--                        :value="$t('common_ConfirmLabel.localization_value.value')"-->
<!--                        :type="'dog'"-->
<!--                        @click.native="showBuyLabelPopup(item.id, true)"-->
<!--                    />-->
<!--                  </template>-->
                  <template v-if="item.prepay === 0 && !isAdmin && isUserPrepayment &&
                  item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id &&
                  item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id &&
                  item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id" slot="item">
                    <LinkButton
                        :value="$t('consolidations_pay.localization_value.value')"
                        :type="'dollar'"
                        @click.native="$emit('payConsolidation', item.id)"
                    />
                  </template>
                  <template v-if="((item.prepay === 1 && isUkrainian) || isAdmin || !isUserPrepayment) && !item.order_internal
                  && (item.status === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_IN_PROGRESS.value || item.status === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_DEPARTED.value)
                  && item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id
                  && item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id
                  && item.consolidation_destination_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id" slot="item">
                    <LinkButton
                        :value="$t('consolidations_novaPoshta.localization_value.value')"
                        :link="$store.getters.GET_PATHS.novaPoshtaCreation + '/' + ORDER_TYPE_CONSOLIDATION + '/' + item.id"
                        :type="'pickup'"
                    />
                  </template>
                  <template v-if="((item.prepay === 1 && isUkrainian) || isAdmin || !isUserPrepayment)
                    && isInternalNovaPoshta(item) && !isInternalLabelCreated(item.order_internal)" slot="item">
                    <LinkButton
                        :value="$t('consolidations_novaPoshtaEdit.localization_value.value')"
                        :link="$store.getters.GET_PATHS.novaPoshta + '/' + item.order_internal.id"
                        :type="'pickup'"
                    />
                  </template>
                  <template v-if="((item.prepay === 1 && isUkrainian) || isAdmin || !isUserPrepayment)
                    && isInternalNovaPoshta(item) && isInternalDraft(item.order_internal)" slot="item">
                    <LinkButton
                        :value="$t('poshta_CreateNovaPoshtaLabel.localization_value.value')"
                        :type="'dog'"
                        @click.native="createLabelNovaPoshta(item)"
                    />
                  </template>
                  <template v-if="isInternalNovaPoshta(item) && isInternalLabelCreated(item.order_internal)" slot="item">
                    <LinkButton
                        :value="$t('consolidations_novaPoshtaShow.localization_value.value')"
                        :link="$store.getters.GET_PATHS.novaPoshta + '/show/' + item.order_internal.id"
                        :type="'pickup'"
                        :target="'_blank'"
                    />
                  </template>
                  <template v-if="isInternalNovaPoshta(item) && isInternalLabelCreated(item.order_internal)" slot="item">
                    <LinkButton
                        :value="$t('poshta_Label.localization_value.value')"
                        :link="$store.getters.GET_PATHS.getPdfFile + '/' + getNovaPoshtaLabelUUID(item.order_internal.file_entries)"
                        :type="'mark'"
                        :target="'_blank'"
                    />
                  </template>
                  <template v-if="isInternalNovaPoshta(item) && isInternalLabelCreated(item.order_internal)" slot="item">
                    <LinkButton
                        :value="$t('poshta_Marking.localization_value.value')"
                        :link="$store.getters.GET_PATHS.getPdfFile + '/' + getNovaPoshtaMarkingUUID(item.order_internal.file_entries)"
                        :type="'mark'"
                        :target="'_blank'"
                    />
                  </template>

                  <template v-if="!item.in_draft && isAdmin && (item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.id || item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.id)" slot="item">
                    <LinkButton
                        :value="$t('common_downloadInnerLabels.localization_value.value')"
                        :type="'mark'"
                        @click.native="downloadInnerLabels(item.id)"
                    />
                  </template>

                  <!--если из своего города, есть лэйба, не от имени склада или же админ-->
                  <template v-if="!item.in_draft && item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id &&
                  item.file_entities && item.file_entities.length > 0 && getFileUUID(item.file_entities, 'pdf', 'labelFileType')
                  && (!item.warehouse || isAdmin) && ((!item.pickup_data && !item.cancel_pickup_data) || !item.pickup_data)" slot="item">
                    <LinkButton
                        :value="$t('common_CallPickup.localization_value.value')"
                        :type="'pickup'"
                        @click.native="showCallingCourierPopup(item)"
                    />
                  </template>

                  <template v-if="!item.in_draft && item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id &&
                  item.file_entities && item.file_entities.length > 0 && getFileUUID(item.file_entities, 'pdf', 'labelFileType')
                  && (!item.warehouse || isAdmin) && (!item.cancel_pickup_data && item.pickup_data)" slot="item">
                    <LinkButton
                        :value="$t('common_CancelCourier.localization_value.value')"
                        :type="'pickup'"
                        @click.native="openCancelCourierPopup(item)"
                    />
                  </template>

                  <template v-if="!item.in_draft && item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id &&
                  item.file_entities && item.file_entities.length > 0 && getFileUUID(item.file_entities, 'pdf', 'labelFileType')
                  && (!item.warehouse || isAdmin) && item.pickup_data" slot="item">
                    <LinkButton
                        :value="$t('common_courierInfo.localization_value.value')"
                        :type="'pickup'"
                        @click.native="showCourierDetailPopup(item)"
                    />
                  </template>


                  <template slot="item"
                            v-if="!isFromYourCityType(item) && item.status === 'completed' && !isLatvian">
                    <LinkButton
                        :value="$t('common_stop.localization_value.value')"
                        :type="'pickup'"
                        @click.native="stopOrderConsolidation(item)"
                    />
                  </template>

                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getConsolidation"
                 :key="index"
            >
              <ConsolidationTableMobile
                  :item="item"
                  :navTabs="navTabs"
                  :selectedNow="selectedNow"
                  @changeTrackingNumberConsolidation="changeTrackingNumberConsolidation"
                  @removeItem="$emit('removeItem', item.id)"
                  @thermalPrint="(data) => $emit('thermalPrint', data)"
                  @getConsolidationMarking="(data) => $emit('getConsolidationMarking', data)"
                  @getConsolidationProform="(id) => { $emit('getConsolidationProform', id) }"
                  @getConsolidationApiProform="(id) => { $emit('getConsolidationApiProform', id) }"
                  @payConsolidation="$emit('payConsolidation', item.id)"
                  @showBuyLabelPopup="(confirm) => showBuyLabelPopup(item.id, confirm)"
                  @confirmLabel="$emit('confirmLabel')"
                  @createLabelNovaPoshta="createLabelNovaPoshta(item)"
                  @showCallingCourierPopup="showCallingCourierPopup(item)"
                  @openCancelCourierPopup="openCancelCourierPopup(item)"
                  @showCourierDetailPopup="showCourierDetailPopup(item)"
                  @downloadInnerLabels="downloadInnerLabels(item.id)"
                  @stopOrderConsolidation="stopOrderConsolidation(item)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getConsolidationCommonList.next_page_url !== null && $store.getters.getConsolidation.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextConsolidationPage}"
              :count="$store.getters.getConsolidationCommonList.total - $store.getters.getConsolidationForPage * $store.getters.getConsolidationCommonList.current_page < $store.getters.getConsolidationForPage ?
                  $store.getters.getConsolidationCommonList.total - $store.getters.getConsolidationForPage * $store.getters.getConsolidationCommonList.current_page:
                  $store.getters.getConsolidationForPage"
          />
          <ExportBtn
              @downloadFiles="type => $emit('downloadFiles', type)"
              class="table-bottom-btn__right"/>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getConsolidationLoading === false && $store.getters.getConsolidation.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

    <PayOrderPopup
        v-if="payOrderPopup"
        :orderPrice="orderPrice"
        :disableBtn="$store.getters.getNovaPoshtaBtn || $store.getters.getOrdersInternalBtn"
        @close="changePayOrderPopup(false)"
        @createLabel="createLabel(orderId, true)"
    />

    <CallingCourierPopup
        v-if="isModalCallingCourierPopup"
        :orderPopupData="orderPopupData"
        :availableCourierTime="availableCourierTime"
        @close="closeCallingCourierPopup"
        @reload="$emit('reload')"
        @successPickup="confirmCallCourier"
    />

    <CancelCourierPopup
        v-if="isModalCancelCourierPopup"
        :id="orderId"
        @close="closeCancelCourierPopup"
        @reload="$emit('reload')"
    />

    <CourierDetailPopup
        v-if="isModalCourierDetailPopup"
        :item="courierDetailData"
        @close="closeCourierDetailPopup"
    />

    <BuyLabelPopup
        v-if="isModalBuyLabelPopup"
        :idCostConsoLabel="idCostConsoLabel"
        :confirmation="isModalBuyLabelPopupConfirm"
        @close="closeBuyLabelPopup"
        @reload="$emit('reload')"
        @payForAuthorize="openPayPopup"
    />

  </div>
</template>

<script>
import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
import InputEditField from "../../../../../../UI/inputs/InputEditField/InputEditField";
import {
  EXPRESS_DELIVERY_SERVICES,
  ORDERS_CONSOLIDATION_TYPE
} from "../../../../../../../staticData/staticVariables";
import {CONSOLIDATION_TYPES} from "../../../../../../../staticData/staticVariables";
import {CONSOLIDATION_STATUSES} from "../../../../../../../staticData/staticVariables";
import {ORDER_TYPE_CONSOLIDATION} from "../../../../../../../staticData/staticVariables";
import {consolidationMixin} from "../../../../../../../mixins/consolidationMixins/consolidationMixin";
import {novaPoshtaOrdersMixins, novaPoshtaOrdersTableMixins} from "../../../../../../../mixins/internalOrdersMixins/novaPoshtaOrdersMixin";
import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
import ConsolidationTableMobile from "./ConsolidationTableMobile/ConsolidationTableMobile";
import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import PayOrderPopup from "../../../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import CallingCourierPopup from "../../../../popups/CallingCourierPopup/CallingCourierPopup";
import CancelCourierPopup from "../../../../popups/CancelCourierPopup/CancelCourierPopup";
import CourierDetailPopup from "../../../../popups/CourierDetailPopup/CourierDetailPopup";
import BuyLabelPopup from "../../../../popups/BuyLabelPopup/BuyLabelPopup";


export default {
  name: "ConsolidationTable",

  components: {
    BuyLabelPopup,
    CourierDetailPopup,
    CancelCourierPopup,
    CallingCourierPopup,
    TooltipTextHelper,
    PayOrderPopup,
    NoResult,
    ConsolidationTableMobile,
    ValueHelper,
    TableUserColumn,
    ShowMore,
    ExportBtn,
    DefaultCheckbox,
    LinkButton,
    ManagerButton,
    InputEditField,
  },

  mixins: [
    mixinDetictingMobile,
    consolidationMixin,
    novaPoshtaOrdersMixins,
    novaPoshtaOrdersTableMixins,
  ],

  props: {
    selectedNow: Boolean,
    countFilterParams: Number,
    navTabs: Object,
  },

  data() {
    return {
      orderId: '',
      payOrderPopup: false,
      showFilter: false,
      isMobile: false,

      showContent: false,
      hideEditField: false,

      show1: false,
      show2: false,
      show3: false,

      ORDERS_CONSOLIDATION_TYPE: ORDERS_CONSOLIDATION_TYPE,
      CONSOLIDATION_TYPES: CONSOLIDATION_TYPES,
      CONSOLIDATION_STATUSES: CONSOLIDATION_STATUSES,
      ORDER_TYPE_CONSOLIDATION: ORDER_TYPE_CONSOLIDATION,

      isModalCallingCourierPopup: false,
      isModalCancelCourierPopup: false,
      isModalCourierDetailPopup: false,
      orderPopupData: {},
      courierDetailData: {},
      availableCourierTime: null,

      isModalBuyLabelPopup: false,
      isModalBuyLabelPopupConfirm: false,
      idCostConsoLabel: -1,
    }
  },


  methods: {

    changeTrackingNumberConsolidation({value, id}) {
      console.log('tracking_num:',id, value);
      this.$store.dispatch('updateTrackNumberConsolidation', {
        id: id,
        data: {tracking_number: value}
      }).then((response) => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordChanged')
            let consolidations = this.$store.getters.getConsolidation
            this._.find(consolidations, {id: id}).user_tracking_number = value
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },




    createLabelNovaPoshta(item) {
      this.orderId = item.order_internal?.id
      if (this.orderId) {
        this.getDeliveryPriceFromTable(item.order_internal.id)
      }
    },

    closeCallingCourierPopup() {
      this.orderPopupId = -1
      this.deliveryNamePopup = ''
      this.isModalCallingCourierPopup = false
    },

    openCancelCourierPopup(item) {
      this.orderId = item.id
      this.isModalCancelCourierPopup = true
    },

    closeCancelCourierPopup() {
      this.orderId = -1
      this.isModalCancelCourierPopup = false
    },

    confirmCallCourier() {
      this.$emit('reload')
      this.isModalCallingCourierPopup = false
    },

    showCallingCourierPopup(item) {
      let type = this._.find(EXPRESS_DELIVERY_SERVICES, {id: item.consolidation_destination.delivery_service_id})

      let senderData = item.user?.use_alternative_address === 1 ? item.user.user_alternative_contact : item.user.user_personal_contact
      let senderWarehouseData = item.warehouse?.id ? item.warehouse : null

      this.orderPopupData = {
        id: item.id,
        name: senderWarehouseData ? senderWarehouseData?.sender_name : senderData?.user_full_name,
        phone: senderWarehouseData ? senderWarehouseData?.sender_phone : senderData?.phone,
        deliveryDate: item?.delivery_date,
        deliveryTypeName: type?.name,
        deliveryTypeId: type?.id,

        country: {
          id: senderWarehouseData ? senderWarehouseData?.country.id : senderData?.country?.id,
          label: senderWarehouseData ? senderWarehouseData?.country?.name : senderData?.country?.name,
          code_iso_2: senderWarehouseData ? senderWarehouseData?.country?.code_iso_2 : senderData?.country?.code_iso_2,
        },
        city: senderWarehouseData ? senderWarehouseData?.sender_city : senderData?.city,
        address: senderWarehouseData ? senderWarehouseData?.sender_address : senderData?.address,
        region: senderWarehouseData ? senderWarehouseData?.sender_contact?.region?.name : senderData?.region?.name,
        zip: senderWarehouseData ? senderWarehouseData?.sender_postcode : senderData?.zip,
      }

      this.isModalCallingCourierPopup = true
    },

    showCourierDetailPopup(item) {

      this.courierDetailData = {
        confirmationNumber: item?.pickup_data?.PickupConfirmationNumber,
        courierArrivalDate: item?.courier_arrival_date,
        contactPersonName: item?.courier_alternative_address ? item?.courier_alternative_address?.contact_name : item?.warehouse ? item?.warehouse?.sender_name : item?.courier_contact_name,
        contactPhone: item?.courier_alternative_address ? item?.courier_alternative_address?.phone : item?.warehouse ? item?.warehouse?.sender_phone : item?.sender_phone_number,
        packageLocation: item?.courier_alternative_address ? item?.courier_alternative_address?.address : item?.warehouse ? item?.warehouse?.sender_address : item?.sender_contact_address,
      }


      if(item.courier_from_to_time !== null) {
        let courierTime = item.courier_from_to_time.split('|')
        this.courierDetailData["timeFrom"] = this.$moment(courierTime[0], 'HH:mm').format('HH:mm')
        this.courierDetailData["timeTo"] = this.$moment(courierTime[1], 'HH:mm').format('HH:mm')
      }

      this.isModalCourierDetailPopup = true
    },

    closeCourierDetailPopup() {
      this.isModalCourierDetailPopup = false
    },

    showBuyLabelPopup(id, confirm = false) {
      this.idCostConsoLabel = id
      this.isModalBuyLabelPopup = true
      this.isModalBuyLabelPopupConfirm = confirm

    },

    closeBuyLabelPopup() {
      this.isModalBuyLabelPopup = false
    },

    openPayPopup(data) {
      this.closeBuyLabelPopup()
      this.$emit('payForAuthorize', data)
    },
  },

}
</script>

<style lang="scss" scoped>

.q-ty-consolidation {

}

.site-table thead th,
.site-table tbody td {
  padding-right: 5px;
  padding-left: 5px;
}

.table-manage-list .manager-btn {
  width: 315px;

  @media (max-width: 1600px) {
    width: 240px;
  }

  @media (max-width: 1430px) {
    width: 150px;
  }
}


.site-table thead th:first-child,
.site-table tbody td:first-child {
  padding-right: 0;
  padding-left: 10px;
}

.site-table thead th:nth-child(2),
.site-table tbody td:nth-child(2) {
  padding-left: 0;
}

.site-table thead th:last-child,
.site-table tbody td:last-child {
  padding-right: 0;
}

.site-table .ellipsis {
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  display: inline-block;
}

@media(max-width: 1600px) {
  .site-table .ellipsis.tacking-number {
    max-width: 60px;
  }

  .hide-1600 {
    display: none;
  }
}

.transform-translate-y--1 {
  transform: translateY(-1px);
  margin-right: 5px;
}

</style>
