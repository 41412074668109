<template>
  <div>
    <ConsolidationHead
        :showFilter="showFilter"
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

      <ConsolidationFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <ConsolidationTable
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          :selectedNow="selectedNow"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @downloadFiles="type => $emit('downloadFiles', type)"
          @removeItem="(id) => { $emit('removeItem', id) }"
          @thermalPrint="(data) => $emit('thermalPrint', data)"
          @payConsolidation="(data) => $emit('payConsolidation', data)"
          @getConsolidationMarking="(data) => $emit('getConsolidationMarking', data)"
          @getConsolidationProform="(id) => { $emit('getConsolidationProform', id) }"
          @getConsolidationApiProform="(id) => { $emit('getConsolidationApiProform', id) }"
          @payForAuthorize="(data) => $emit('payForAuthorize', data)"
      />
    </div>
  </div>
</template>

<script>


  import ConsolidationHead from "./ConsolidationHead/ConsolidationHead";
  import ConsolidationTable from "./ConsolidationTable/ConsolidationTable";
  import ConsolidationFilter from "./ConsolidationFilter/ConsolidationFilter";

  export default {
    name: "ConsolidationTableUser",

    components: {
      ConsolidationHead,
      ConsolidationTable,
      ConsolidationFilter,
    },

    props: {
      typeShops: String,
      countFilterParams: Number,
      filterGetParams: Object,
      navTabs: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,

        isModalCommentsPopup: '',
        isModalSendEmailPopup: '',
        isModalLastFiveIPPopup: '',
        isModalStatisticPopup: '',
      }
    },

    watch: {
      typeShops(newVal) {
        this.typeShops = newVal
      },
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },

      changeCommentsPopup(val){
        this.isModalCommentsPopup = val
      },
      changeSendEmailPopup(val){
        this.isModalSendEmailPopup = val
      },
      changeLastFiveIPPopup(val){
        this.isModalLastFiveIPPopup = val
      },
      changeStatisticPopup(val){
        this.isModalStatisticPopup = val
      },
    }
  }
</script>

<style scoped>

</style>


