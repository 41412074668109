<template>
  <div class="fragment">

    <ConsolidationTableUser
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @changeTab="changeTab"
        @resetFilter="resetFilter"
        @changeFilter="changeFilter"
        @showMore="showMore"
        @reload="reload"
        @removeItem="removeItem"
        @thermalPrint="thermalPrint"
        @getConsolidationMarking="getConsolidationMarking"
        @getConsolidationProform="getConsolidationProform"
        @getConsolidationApiProform="getConsolidationApiProform"
        @payConsolidation="getCostConsolidation"
        @downloadFiles="downloadFiles"
        @payForAuthorize="payForAuthorize"
    />

    <PayOrderPopup
        v-if="payOrderPopup"
        :payOrderPopupData="payOrderPopupData"
        @pay="payConsolidation"
        @close="payOrderPopup = false"
    />

    <AuthorizePaymentPopup
        v-if="authorizePaymentPopup"
        :countMoney="authorizePaySum"
        :authorizePayData="authorizePayData"
        :authorizeError="authorizeError"
        :serverError="serverError"
        :consolidationType="consolidationType"
        :consolidationData="payOrderPopupData"
        :changeCloseToSaveBtn="false"
        @close="authorizePaymentPopup = false"
        @submitForm="submitAuthorizePay"
    />

    <BraintreePaymentPopup
        v-if="braintreePaymentPopup"
        :amount="authorizePaySum"
        :payData="authorizePayData"
        :consolidationType="consolidationType"
        :consolidationData="payOrderPopupData"
        :changeCloseToSaveBtn="false"
        :invoicePayId="authorizeInvoiceId"
        @submitForm="afterBrainTreePay"
        @close="braintreePaymentPopup = false"
    />

    <ThankYouPopup
        v-if="thankYouPopupPopup"
        @close="closeThankYouPopupPopup"
        :title="'common_paySuccessTitle'"
        :text="'common_paySuccessText'"
        :linkLastText="'common_paySuccessTextLink'"
        :linkLastTextHref="$store.getters.GET_PATHS.financeMyPayments + '?type=authorize'"
    />

    <SendFromDataPopup
        v-if="isSendFromDataPopup"
        :type="'info'"
        @close="isSendFromDataPopup = false"
    />

  </div>
</template>

<script>
  import ConsolidationTableUser from "./ConsolidationTableUser/ConsolidationTableUser";
  import {
    DHL_DELIVERY_SERVICE,
    FEDEX_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE, CONSOLIDATION_TYPES,
    DPD_DELIVERY_SERVICE, ORDERS_CONSOLIDATION_TYPE, EU_PRODUCT_DELIVERY_SERVICE
  } from "../../../../../staticData/staticVariables";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import AuthorizePaymentPopup from "@/components/coreComponents/Popups/AuthorizePaymentPopup/AuthorizePaymentPopup";
  import ThankYouPopup from "@/components/coreComponents/Popups/ThankYouPopup/ThankYouPopup";
  import {paymentBlockFunc} from "@/mixins/dashboardMixins/paymentBlockFunc";
  import PayOrderPopup from "@/components/modules/ConsolidationModule/popups/PayOrderPopup/PayOrderPopup";
  import {consolidationMixin} from "@/mixins/consolidationMixins/consolidationMixin";
  import BraintreePaymentPopup from "@/components/coreComponents/Popups/BraintreePaymentPopup/BraintreePaymentPopup";
  import SendFromDataPopup from "@/components/coreComponents/Popups/SendFromDataPopup/SendFromDataPopup";

  export default {
    name: "ConsolidationTable",
    components: {
      SendFromDataPopup,
      BraintreePaymentPopup,
      PayOrderPopup,
      ThankYouPopup,
      AuthorizePaymentPopup,
      ConsolidationTableUser,
    },

    mixins: [routeFilter, paymentBlockFunc, consolidationMixin],

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    watch: {
      router: function(newRoute) {
        if(Object.keys(newRoute).length === 0){
          this.changeTab('all')
        }
      },

      loadUserAuthorizedData: function () {
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      },

      downloadPermissions() {
        this.checkTabPermission()
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getConsolidationForPage,
        page: 1,
        onePage: 1,
        filterType: '',

        filterConsolidationId: '',
        filterProductCount: '',
        filterCreatedDate: '',
        filterShippedDate: '',
        filterReceivedDate: '',

        filterUserTrackingNumber: '',
        filterTrackingNumber: '',

        filterUserId: '',
        filterUserName: '',

        filterPaid: '',
        filterPromoCode: '0',

        filterSendFrom: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          all: {
            active: true,
            label: this.$t('consolidations_allOrders.localization_value.value'),
            // subLabel: this.$t('consolidations_allOrdersSub.localization_value.value'),
            name: 'all',
            id: false,
            permissionVisible: true,
          },
          // [DHL_DELIVERY_SERVICE.name]: {
          //   active: false,
          //   label: this.$t('express_DHL.localization_value.value'),
          //   subLabel: this.$t('consolidations_DHLSub.localization_value.value'),
          //   ico: '/img/company-icons-group/tab-dhl.svg',
          //   icoWidth: '105',
          //   name: DHL_DELIVERY_SERVICE.name,
          //   id: DHL_DELIVERY_SERVICE.id,
          //   permissionVisible: false,
          // },
          [ORDERS_CONSOLIDATION_TYPE.FEDEX]: {
            active: false,
            label: this.$t('consolidations_USAFedex.localization_value.value'),
            // subLabel: this.$t('consolidations_fedexSub.localization_value.value'),
            // ico: '/img/company-icons-group/tab-fedex.svg',
            // icoWidth: '80',
            name: ORDERS_CONSOLIDATION_TYPE.FEDEX,
            id: FEDEX_DELIVERY_SERVICE.id,
            permissionVisible: true,
          },
          [ORDERS_CONSOLIDATION_TYPE.DHL_USA]: {
            active: false,
            label: this.$t('consolidations_USADHL.localization_value.value'),
            // subLabel: this.$t('consolidations_fedexSub.localization_value.value'),
            // ico: '/img/company-icons-group/tab-fedex.svg',
            // icoWidth: '80',
            name: ORDERS_CONSOLIDATION_TYPE.DHL_USA,
            id: DHL_DELIVERY_SERVICE.id,
            permissionVisible: false,
          },
          // [TNT_DELIVERY_SERVICE.name]: {
          //   active: false,
          //   label: this.$t('express_tnt.localization_value.value'),
          //   subLabel: this.$t('consolidations_tntSub.localization_value.value'),
          //   ico: '/img/company-icons-group/tab-tnt.svg',
          //   icoWidth: '55',
          //   name: TNT_DELIVERY_SERVICE.name,
          //   id: TNT_DELIVERY_SERVICE.id,
          //   permissionVisible: false,
          // },
          [ORDERS_CONSOLIDATION_TYPE.DPD]: {
            active: false,
            label: this.$t('consolidations_consolidationEU.localization_value.value'),
            // subLabel: this.$t('consolidations_dpdSub.localization_value.value'),
            // ico: '/img/company-icons-group/tab-dpd.svg',
            // icoWidth: '80',
            name: ORDERS_CONSOLIDATION_TYPE.DPD,
            id: DPD_DELIVERY_SERVICE.id,
            permissionVisible: true,
          },
          [ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT]: {
            active: false,
            label: this.$t('consolidations_consolidationEUProduct.localization_value.value'),
            // subLabel: this.$t('consolidations_dpdSub.localization_value.value'),
            // ico: '/img/company-icons-group/tab-dpd.svg',
            // icoWidth: '80',
            name: ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT,
            id: EU_PRODUCT_DELIVERY_SERVICE.id,
            permissionVisible: true,
          },
          [ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY]: {
            active: false,
            label: this.$t('consolidations_yourCity.localization_value.value'),
            name: ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY,
            id: EU_PRODUCT_DELIVERY_SERVICE.id,
            permissionVisible: true,
          },
        },


        payOrderPopupData: null,

        payOrderPopup: false,

        serverError: false,
        authorizePayData: {},
        authorizePaySum: -1,
        authorizeInvoiceId: -1,
        authorizeBalanceAmount: -1,
        payConsoId: -1,
        bankFeeds: null,
        authorizePaymentPopup: false,
        braintreePaymentPopup: false,
        thankYouPopupPopup: false,
        authorizeError: false,
        consolidationType: false,

        isSendFromDataPopup: false,
      }
    },

    mounted() {

      if(!this.isAdmin) {
        this.$store.dispatch('fetchOrderSendFrom', '?limit=1').then(response => {
          if(this.getRespPaginateData(response).length === 0) {
            this.isSendFromDataPopup = true
          }
        })
      }

      if(this.$route.query.type !== undefined){
        this.navTabs.all.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      if(this.loadUserAuthorizedData){
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      }
    },

    methods: {

      closeThankYouPopupPopup() {
        this.thankYouPopupPopup = false
      },

      submitAuthorizePay(data){
        if (!this.consolidationType) {
          this.payAuthorizeInvoice({data: data, invoiceId: this.authorizeInvoiceId}).then(status => {
            if(status){
              this.$store.dispatch('consolidationAfterInvoicePayStatusUpdate', this.payConsoId)
            }
            this.authorizePaymentPopup = false

            setTimeout(() => {
              this.changeThankYouPopupPopup(true)
            }, 200)
          })
        }
        else {
          this.bankFeeds = this.authorizePaySum
          if(this.authorizeBalanceAmount > -1) {
            data.balanceAmount = this.authorizeBalanceAmount
          }
          this.payAuthorize(data).then(() => {
            this.authorizePaymentPopup = false

            setTimeout(() => {
              this.changeThankYouPopupPopup(true)
            }, 200)
          })
        }
      },

      afterBrainTreePay(){

        if (!this.consolidationType) {
          this.$store.dispatch('consolidationAfterInvoicePayStatusUpdate', this.payConsoId).then(() => {
            this.braintreePaymentPopup = false

            setTimeout(() => {
              this.changeThankYouPopupPopup(true)
            }, 200)
          })
        }else {
          this.braintreePaymentPopup = false

          setTimeout(() => {
            this.changeThankYouPopupPopup(true)
          }, 200)
        }
      },

      getCostConsolidation(id) {
        this.$store.dispatch('getCostConsolidation', id).then(response => {
          let data = this.getRespData(response)
          this.payOrderPopupData = this.getRespData(response)
          let total = Number(data?.shipping_cost_total)
          if(data?.totals?.consolidation?.vat_amount) {
            total += Number(data.totals.consolidation.vat_amount)
          }
          if(total > Number(data?.user_balance)){
            this.payConsolidation(id)
          } else {
            this.payOrderPopup = true
          }
        }).catch(error => this.createErrorLog(error))
      },

      payConsolidation(id) {
        this.$store.dispatch('payConsolidation', {id: id}).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              if(!this.getRespData(response)?.status){
                this.payOrderPopup = false
                this.authorizePaySum = this.getRespData(response)?.total_amount
                this.authorizeBalanceAmount = this.getRespData(response)?.balance_amount
                this.payOrderPopupData = this.getRespData(response)


                this.consolidationType = true
                if(this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE)) {
                  this.braintreePaymentPopup = true
                } else {
                  this.authorizePaymentPopup = true
                }

              } else {
                this.authorizeBalanceAmount = -1
                this.payOrderPopup = false
                this.openNotify('success', 'common_notificationRecordChanged')
                this.reload()
              }
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        }).catch(error => this.createErrorLog(error))
      },

      payForAuthorize({labelNeedMoneyAmount, invoiceId, userBalance, orderAmount, paySystemCommission, idCostConsoLabel}) {
        this.authorizePayData = {
          userBalance,
          orderAmount,
          paySystemCommission,
        }
        this.authorizePaySum = labelNeedMoneyAmount
        this.authorizeInvoiceId = invoiceId
        this.payConsoId = idCostConsoLabel

        this.consolidationType = false
        if(this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE)) {
          this.changeBraintreePaymentPopup(true)
        } else {
          this.changeAuthorizePaymentPopup(true)
        }
      },

      checkTabPermission() {

        if (!this.isAdmin && this.isBaltic) {
          this.navTabs = {
            all: {
              active: true,
              label: this.$t('consolidations_allOrders.localization_value.value'),
              name: 'all',
              id: false,
              permissionVisible: true,
            },
            [ORDERS_CONSOLIDATION_TYPE.FEDEX]: {
              active: false,
              label: this.$t('consolidations_consolidationUSA.localization_value.value'),
              name: ORDERS_CONSOLIDATION_TYPE.FEDEX,
              id: FEDEX_DELIVERY_SERVICE.id,
              permissionVisible: true,
            },
            [ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY]: {
              active: false,
              label: this.$t('consolidations_yourCity.localization_value.value'),
              name: ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY,
              id: EU_PRODUCT_DELIVERY_SERVICE.id,
              permissionVisible: true,
            },
          }
        }

        if (!this.isAdmin && (this.isUnitedKingdomCountryByUserData(this.isUseAlternativeContact ?
            this.$store.getters.getUserProfile?.user_alternative_contact?.country_id : this.$store.getters.getUserProfile?.user_personal_contact?.country_id))) {
          this.navTabs = {
            all: {
              active: true,
              label: this.$t('consolidations_allOrders.localization_value.value'),
              name: 'all',
              id: false,
              permissionVisible: false,
            },
            [ORDERS_CONSOLIDATION_TYPE.FEDEX]: {
              active: false,
              label: this.$t('consolidations_consolidationUSA.localization_value.value'),
              name: ORDERS_CONSOLIDATION_TYPE.FEDEX,
              id: FEDEX_DELIVERY_SERVICE.id,
              permissionVisible: true,
            },
            [ORDERS_CONSOLIDATION_TYPE.DHL_USA]: {
              active: false,
              label: this.$t('consolidations_USADHL.localization_value.value'),
              name: ORDERS_CONSOLIDATION_TYPE.DHL_USA,
              id: DHL_DELIVERY_SERVICE.id,
              permissionVisible: false,
            },
          }
        }

        if (!this.isAdmin && (this.isPoland || this.isUserAlternativeContactPoland)) {
          this.navTabs = {
            all: {
              active: true,
              label: this.$t('consolidations_allOrders.localization_value.value'),
              name: 'all',
              id: false,
              permissionVisible: true,
            },
            [ORDERS_CONSOLIDATION_TYPE.FEDEX]: {
              active: false,
              label: this.$t('consolidations_consolidationUSA.localization_value.value'),
              name: ORDERS_CONSOLIDATION_TYPE.FEDEX,
              id: FEDEX_DELIVERY_SERVICE.id,
              permissionVisible: true,
            },
            [ORDERS_CONSOLIDATION_TYPE.DHL_USA]: {
              active: false,
              label: this.$t('consolidations_USADHL.localization_value.value'),
              name: ORDERS_CONSOLIDATION_TYPE.DHL_USA,
              id: DHL_DELIVERY_SERVICE.id,
              permissionVisible: false,
            },
            [ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY]: {
              active: false,
              label: this.$t('consolidations_yourCity.localization_value.value'),
              name: ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY,
              id: EU_PRODUCT_DELIVERY_SERVICE.id,
              permissionVisible: true,
            },
          }
        }


        if (this.isAdmin || this.isUkrainian) {
          this.navTabs[ORDERS_CONSOLIDATION_TYPE.DHL_USA].permissionVisible = true
        }

        // this.activeTabByPermissions('navTabs', {
        //   // [DHL_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_DHL,
        //   // [FEDEX_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        //   // [TNT_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_TNT,
        //   // [DPD_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
        // })

        this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])

        if(!this._.find(this.navTabs, {active: true}).permissionVisible){
          this.setActivePermissionVisibleTab('navTabs')
          return
        }

        if(this.isAdmin && !this.$route.query.paid){
          this.$route.query.paid = '1'
        }

        if(this.isAdmin && !this.$route.query.promoCode){
          this.$route.query.promoCode = '0'
        }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          consolidationId: this.$route.query.consolidationId,
          productCount: this.$route.query.productCount,
          createdDate: this.$route.query.createdDate,
          shippedDate: this.$route.query.shippedDate,
          receivedDate: this.$route.query.receivedDate,

          userTrackingNumber: this.$route.query.userTrackingNumber,
          trackingNumber: this.$route.query.trackingNumber,

          userId: this.$route.query.userId,
          userName: this.$route.query.userName,

          paid: this.$route.query.paid,
          promoCode: this.$route.query.promoCode,

          sendFrom: this.$route.query.sendFrom,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.commit('setConsolidationFilter', window.location.search)

        if (next) this.$store.commit('setNextConsolidationPage', true)
        this.$store.dispatch('fetchConsolidation', {filter: url}).then(() => {
          this.$store.commit('setNextConsolidationPage', false)
        })


        this.checkCountFilter(['userName', 'type'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterConsolidationId: 'ConsolidationId',
              filterProductCount: 'byProductCount',
              filterUserTrackingNumber: 'ConsolidationUserTrackingNumber',
              filterTrackingNumber: 'ConsolidationTrackingNumber',
              filterUserId: 'ConsolidationUserId',
              filterPaid: 'byPayedInvoice',
              filterPromoCode: 'HasPromocode',
              filterSendFrom: 'bySendFromCompanyName',
              // filterContactName: 'OrderExpressPaymentRecipientContactName',
              // filterEmail: 'OrderExpressPaymentRecipientEmail',
              // filterTransactionNumber: 'transactionId',
              // filterTrackingNumber: 'OrderExpressPaymentTrackingNumber',
              // filterTrackingNumberNPoshta: 'OrderExpressNewPostTrackingNumber',
              // filterPaymentStatus: 'OrderExpressPaymentStatus',
              // filterCountryId: 'OrderExpressPaymentRecipientCountryId',
              // filterUserId: 'OrderExpressPaymentUserId',
              // filterItemName: 'proform_entity_naming',
            },
        )

        myQuery.appends('warehouseData')

        let destinationId = ''
        switch (this.filterType) {
          case ORDERS_CONSOLIDATION_TYPE.DHL_USA:
            destinationId = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.id
            break
          case ORDERS_CONSOLIDATION_TYPE.FEDEX:
            destinationId = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.id
            break
          case TNT_DELIVERY_SERVICE.name:
            destinationId = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_TNT.id
            break
          case ORDERS_CONSOLIDATION_TYPE.DPD:
            destinationId = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DPD.id
            break
          case ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT:
            destinationId = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_EU_PRODUCT.id
            break
          case ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY:
            destinationId = CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id + ',' +
                CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id + ',' +
                CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id
            break
        }
        if (destinationId !== '')
          myQuery.where('consolidationDestinationId', destinationId)

        this.filterCreatedDate = this.$route.query.createdDate !== undefined ? this.$route.query.createdDate : ''
        if (this.filterCreatedDate.length > 0) {
          let date = this.generateDateForFilter(this.filterCreatedDate, 'YYYY-MM-DD')
          myQuery.whereIn(
              'byCreatedAt',
              date
          )
        }

        this.filterShippedDate = this.$route.query.shippedDate !== undefined ? this.$route.query.shippedDate : ''
        if (this.filterShippedDate.length > 0) {
          let date = this.generateDateForFilter(this.filterShippedDate, 'YYYY-MM-DD')
          myQuery.whereIn(
              'byShippedAt',
              date
          )
        }

        this.filterReceivedDate = this.$route.query.receivedDate !== undefined ? this.$route.query.receivedDate : ''
        if (this.filterReceivedDate.length > 0) {
          let date = this.generateDateForFilter(this.filterReceivedDate, 'YYYY-MM-DD')
          myQuery.whereIn(
              'byReceivedAt',
              date
          )
        }


        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      removeItem(id){
        let text = {
          title: 'consolidations_deleteConsolidation',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.$store.dispatch('deleteConsolidation', id).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordDeleted')
                this.$emit('close')
                //filter to upgrade table after deleting an item
                this.filter()
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },



      getConsolidationMarking(data) {
        let type = 'docx'
        this.$store.dispatch('getConsolidationFileBlob', {id: data.id, type: type}).then((response) => {
          this.downloadBlobFile(response, type)
        }).catch(error => this.createErrorLog(error))
      },

      getConsolidationProform(id) {
        let requestData = {
          'consolidation_id': id,
        }

        this.$store.dispatch('getConsolidationProformFile', requestData).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */

            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              const blob = new Blob(["\ufeff", response.data], {type: 'application/csv'})
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = `Proforma ${id}.csv`
              link.click()
              URL.revokeObjectURL(link.href)

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        }).catch(error => this.createErrorLog(error))
      },

      getConsolidationApiProform(id) {

        this.$store.dispatch('getGetFileEntries', id).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */

            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.globalDownloadBase64File('application/docx', response.data.data.fileContentBase64, 'proformFile.docx')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        }).catch(error => this.createErrorLog(error))
      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        if(type === 'xls') {
          type = 'xlsx'
        }

        this.$store.dispatch('getExportConsolidation', {filter: url, exportType: type}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: `application/${type}`})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `Consolidations.${type}`
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        }).catch(error => this.createErrorLog(error))
      }

    },

    destroyed() {
      setTimeout(() => {
        this.$store.commit('setConsolidation', {consolidation: []})
        this.$store.commit('setConsolidationCommonList', {consolidationCommonList: []})
      }, 300)
    },
  }
</script>

<style scoped>

</style>